// Packages
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch } from "react-instantsearch-dom"

// Components
import ClientSideOnly from "@components/global/client-side-only"
import Navigation from "@components/global/navigation"
import Modal from "@components/global/modal-team-apply"

// -Footers
import ASAFooter from "@components/asa/footer"
import LEPFooter from "@components/global/footer-lep"
import AlumSponsorFooter from "@components/alumSponsor/footer"
import WalledFooter from "@components/global/footer-walled"
import WorksFooter from "@components/works/footer"
import DisasterReliefFooter from "@components/disaster-relief/footer"

// Styles
const PageWrapper = styled.div`
  position: relative;
`

// Functions
const getClonedChildren = (children, props) =>
  React.cloneElement(children, {
    ...props,
  })

function LayoutContent(props) {
  const queryParams = new URLSearchParams(props.location.search)
  const queryParamLanguage =
    queryParams.get("lang") === "esp" ? "spanish" : "english"

  const bankerData = props.bankerData
  let { type, disclosure, category, pageName } = props.pageContext

  const [searchBoxFocus, setSearchBoxFocus] = useState(false)
  const [language, setLanguage] = useState(queryParamLanguage)
  const [teamApplyToggle, setTeamApplyToggle] = useState(false)
  const [currentLocation, setCurrentLocation] = useState(props.location)
  const [navIsOpen, setNavOpen] = useState(false)
  const [navDropdownOpen, setNavDropdownOpen] = useState(false)

  const mainRef = useRef(null)

  // Flag to open team apply window (for eSig team apply links)
  useEffect(() => {
    props.location.search &&
      props.location.search.includes("team-apply") &&
      bankerData?.team &&
      setTeamApplyToggle(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Close KC search box when navigating to another page
  useEffect(() => {
    setSearchBoxFocus(searchBoxFocus => {
      if (searchBoxFocus) return false
      else return searchBoxFocus
    })
    setCurrentLocation(props.location)
  }, [props.location, setNavOpen, navIsOpen])

  useEffect(() => {
    setNavOpen(navIsOpen => {
      if (navIsOpen) return false
      else return navIsOpen
    })

    // Close dropdown when page changes
    setNavDropdownOpen(true)
    setTimeout(() => {
      setNavDropdownOpen(false)
    })

    // Update language preference when the route changes
    if (language !== queryParamLanguage) setLanguage(queryParamLanguage)
  }, [currentLocation])

  useEffect(() => {
    if (searchBoxFocus) {
      document.querySelector(".layout").scrollIntoView()
    }
  }, [searchBoxFocus])

  let allProps = {
    ...props,
    ...props.pageContext,
    bankerData,
    navIsOpen,
    navDropdownOpen,
    setNavOpen,
    pageName,
    mainRef,
  }

  let footerProps = {
    disclosure,
    bankerData,
    pageName,
    ...props,
    version: props.pageContext.version,
    type: props.pageContext.type,
    mainRef,
  }

  let Footer = WalledFooter
  let searchClient = { search: () => {} } // set as empty properties so algolia requests will only run when in knowledge center

  const noNavPages = ["disaster-relief"]
  /*************************** Alum Sponsor ***************************/
  if (type === "alumSponsor") {
    Footer = AlumSponsorFooter
  }

  /*************************** ASA ***************************/
  if (type === "asa") {
    Footer = ASAFooter
  }

  /*************************** Walled/MB ***************************/
  if (type === "walled" || type === "mb") {
    allProps = {
      ...allProps,
      teamApplyToggle,
      setTeamApplyToggle,
    }
    footerProps = {
      ...footerProps,
      params: props.params,
    }
  }

  /*********** -LEP (Walled category)  ***********/
  if (category === "lep") {
    allProps = {
      ...allProps,
      setLanguage,
      language,
    }

    footerProps = {
      ...footerProps,
      disclosure: language === "english" ? "la-bahia" : "la-bahia-spanish",
      language,
    }
    Footer = LEPFooter
  }

  /*********** -KC (Walled category) ***********/
  if (category === "kc") {
    allProps = {
      ...allProps,
      setSearchBoxFocus,
      searchBoxFocus,
      articleProgressBarRoot: mainRef,
    }
    searchClient = algoliasearch(
      process.env.GATSBY_ALGOLIA_APP_ID,
      process.env.GATSBY_ALGOLIA_SEARCH_KEY,
    )
  }
  /*********** -Works/Advantage (Walled page)  ***********/
  if (
    pageName === "works" ||
    pageName === "advantage" ||
    pageName === "seller-success"
  ) {
    Footer = WorksFooter
  }
  /*********** -Disaster Relief (Corporate page) ***********/
  if (pageName === "disaster-relief") {
    Footer = DisasterReliefFooter
  }

  return (
    <PageWrapper className="layout">
      <InstantSearch searchClient={searchClient} indexName="knowledge-center">
        {pageName?.match("404") ? (
          <ClientSideOnly
            placeholder={<div style={{ minHeight: "168px" }}></div>}
          >
            <Navigation {...allProps} />
          </ClientSideOnly>
        ) : noNavPages.includes(pageName) ? null : (
          <Navigation {...allProps} />
        )}
        {!navIsOpen && (
          <>
            <main ref={mainRef}>
              {getClonedChildren(props.children, {
                ...allProps,
                mainRef,
              })}
            </main>
            {pageName?.match("404") ? (
              <ClientSideOnly
                placeholder={<div style={{ minHeight: "600px" }}></div>}
              >
                <Footer {...footerProps} />
              </ClientSideOnly>
            ) : (
              <Footer {...footerProps} />
            )}
          </>
        )}
      </InstantSearch>
      {teamApplyToggle && (
        <Modal toggleModal={setTeamApplyToggle} team={bankerData?.team} />
      )}
    </PageWrapper>
  )
}
export default LayoutContent
